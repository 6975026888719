.home{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.home h1{
    padding: 0;
    margin: 0;
    font-size: 5rem;
    color: #7843e6;
    text-shadow: 2px 2px 2px #ff00ff;
}

.home h2{
    padding: 0;
    margin: 0;
    font-size: 4rem;
    color: #ff83a8;
    font-family: Nickainley;
}

.home-sub{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.home-sub img{
    width: 8%;
    aspect-ratio: 1/1;
}

.home-pres{
    font-size: 1.3rem;
    padding: 1rem;
    text-align: center;
}

.home-contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;

}

.home-contact a{
    text-decoration: none;
    color: #7843e6;
    font-size: 3rem;
    transition: transform 0.3s;
}

.home-contact a:hover{
    transform: scale(1.1);
}

.home-contact a:focus{
    outline: none;
}



@media screen and (max-width: 1024px){
    .home h1{
        font-size: 3rem;
    }

    .home h2{
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 768px){
    .home h1{
        font-size: 2.5rem;
    }

    .home h2{
        font-size: 2rem;
    }

    .home-sub img{
        width: 10%;
    }
}

@media screen and (max-width: 640px){
    .home h1{
        font-size: 2rem;
    }

    .home h2{
        font-size: 2rem;
    }
}

@media screen and (max-width: 400px){
    .home h1{
        font-size: 1.5rem;
    }

    .home h2{
        font-size: 1.5rem;
    }

    .home-pres {
        font-size: 1rem;
    }
}