.piano-scroll {
  width: 100%;
  height: 100svh;
  overflow: hidden;
  position : relative;
  display: flex;
  flex-direction: column;
}

.piano-load{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: rgb(97, 97, 97);
  z-index: 1;
  height: 212px;
  width: 100%;
  position : absolute;
  bottom: 0;
  z-index: 20;
  box-shadow: 0px 0px 10px 0px black;
}

.piano-load h1{
  font-size: 2rem;
  color: white;
}

.piano-load button{
  padding: 10px 20px;
  background-color: #38b6ff;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5rem;
  transition: transform 0.3s;
}

.piano-load button:hover{
  transform: scale(1.1);
}

.piano-load button:focus{
  outline: none;
}
