.piano{
    display: flex;
    flex-direction: row;
    background-color: white;
    z-index: 20;
    box-shadow: 0px 0px 10px 0px black;
}

.octave {
    display: flex;
    position: relative;
}

.key {
    width: 40px;
    height: 200px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 5px 1px rgba(32,32,32,.2);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    transition: background 0.05s ease, box-shadow 0.05s ease;
    -webkit-tap-highlight-color: transparent;
}

.key:active ,
.key.active{
    top : 5px;
    box-shadow: none;
}


.key.natural {
    background: white;
    border: 1px solid black;
    z-index: 2;
}

.key.natural.C:active,
.key.natural.C.active{
    background: #ff2323;
}

.key.natural.D:active ,
.key.natural.D.active{
    background: #ff6a23;
}

.key.natural.E:active,
.key.natural.E.active{
    background: #ffae23;
}

.key.natural.F:active, 
.key.natural.F.active{
    background: #95ff32;
}

.key.natural.G:active, 
.key.natural.G.active{
    background: #32ffdd;
}

.key.natural.A:active, 
.key.natural.A.active{
    background: #3235ff;
}

.key.natural.B:active, 
.key.natural.B.active{
    background: #9c24f7;
}

.key.sharp:active {
    background: linear-gradient(-20deg,#000,#000,#000);
}


.key.sharp {
    width: 30px;
    height: 120px;
    background: linear-gradient(-20deg,#222,#000,#222);
    border: 1px solid black;
    color : white;
    position: absolute;
    z-index: 3;
}

/* Position each black key */
.key.sharp.C-sharp {
    left: 26px;
}

.key.sharp.D-sharp {
    left: 70px;
}

.key.sharp.F-sharp {
    left: 154px;
}

.key.sharp.G-sharp {
    left: 197px;
}

.key.sharp.A-sharp {
    left: 240px;
}

@media screen and (width < 1200px){
    .piano{
        transform: translateX(-882px);
    }
}
