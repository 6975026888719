body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 9px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #38b6ff;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background-color: rgb(182, 182, 182);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #7843e6;
}

::-webkit-scrollbar-thumb:active {
  background-color: #7843e6;
}

.bungee-regular {
  font-family: "Bungee", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.titillium-web-light {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.titillium-web-regular {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.titillium-web-bold {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.titillium-web-regular-italic {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-style: italic;
}



@font-face {
  font-family: "Nickainley";
  src: url("../public/Nickainley-Normal.otf") format("truetype");
  font-style: normal;
}