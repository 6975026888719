.card {
    flex : 1 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position : sticky;
    top : 0;
    height: 100%;
    box-shadow: 0px 0px 10px 0px black;    
}