.fleur{
    background-color: #ff83a8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.fleur h1{
    padding: 0;
    margin: 0;
    font-size: 5rem;
    font-family: Nickainley;
    color: white;
    text-shadow: 2px 2px 2px #38b6ff;
}

.fleur-pres{
    font-size: 1.3rem;
    padding: 1rem;
    text-align: center;
    color : white;
}

.fleur-music{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.spotify{
    width: 800px;
    height: 152px;
}

.fleur-music p{
    font-size: 1.3rem;
    color: white;
}

.fleur-music a{
    text-decoration: none;
    color: #7843e6;
    font-size: 1.3rem;
    transition: transform 0.3s;
}

@media screen and (max-width : 880px){
    .fleur h1{
        font-size: 4rem;
    }

    .fleur-pres{
        font-size: 1.1rem;
    }

    .fleur-music p{
        font-size: 1.1rem;
    }

    .fleur-music a{
        font-size: 1.1rem;
    }

    .spotify{
        width: 600px;
        height: 152px;
    }
}

@media screen and (max-width : 768px){
    .fleur h1{
        font-size: 3.5rem;
    }

    .fleur-pres{
        font-size: 1rem;
    }

    .fleur-music p{
        font-size: 1rem;
    }

    .fleur-music a{
        font-size: 1rem;
    }
}

@media screen and (max-width : 640px){
    .spotify{
        width: 450px;
        height: 152px;
    }
}

@media screen and (max-width : 480px){
    .fleur h1{
        font-size: 2rem;
    }

    .fleur-pres{
        font-size: 0.8rem;
    }

    .fleur-music p{
        font-size: 0.8rem;
    }

    .fleur-music a{
        font-size: 0.8rem;
    }
    
    .spotify{
        width: 350px;
        height: 152px;
    }
}
